/*
Template Name: Flourish
Author: Ingrid Kuhn
Author URI: themeforest/user/ingridk
Version: 1.0
Last change: 08 june 2018 

	Table of Contents

	#General and Typography
	#Preloader
	#Navigation
	#Slideshow
	#Structure
	#Section - Services
	#Section - About us	
	#Section - Gallery
	#Section - Contact
	#Miscellaneous
	#Media Queries

/*=========================== General and Typography ============================ */
 body {
     color: #797979;
     font-family: 'Lato',sans-serif;
}
 p,ul,ol {
     font-size: 16px;
     line-height: 1.8;
     font-weight: 400;
}
 h1 {
     font-size: 3.7em;
     line-height: 1em;
     font-family: 'Raleway', serif;
     margin-bottom: 15px;
     font-weight: 700;
	 letter-spacing: -0.5px;
}
 h1 span {
     font-weight: 400;
}
 h2 {
     margin-bottom: 20px;
	 letter-spacing: 1px;
     font-family: 'Raleway', serif;
     font-size: 3.1em;
     margin-bottom: 20px;
	 text-transform: uppercase;
     font-weight: 700;
}
 h3 {
     font-family: 'Raleway', sans-serif;
     font-size: 2.7em;
	 font-weight: 700;
     letter-spacing: -0.2px;
     margin-bottom: 20px;
     line-height: 1.2;
	 color: #4E4E4E;
}
 h3.no-margin-top {  
     margin-top: 0px;
}
 h4 {
     font-family: 'Raleway', serif;
     color: #4E4E4E;
     font-size: 2.1em;
     line-height: 1.5em;
     font-weight: 700;
     margin-bottom: 20px;
}
 h5 {
     color: #4E4E4E;
     font-family: 'Raleway', serif;
     font-size: 1.8em;
     line-height: 1.2;
     font-weight: 700;
     margin-bottom: 20px;
}
 h6 {
     color: #4E4E4E;
     font-family: 'Raleway', serif;
     line-height: 1.4em;
     font-size: 1.4em;
     font-weight: 700;
     margin-bottom: 20px;
}
 .h7 {
     font-size: 18px;
     line-height: 28px;
     font-weight: 700;
}
 .italic {
     font-style: italic;
}
 .bold {
     font-weight: 700;
}
 .lead {
     font-weight: 400;
	 font-size: 17px;
	 margin-bottom: 10px;
     line-height: 1.6em;
}
.small-text {
     font-family: 'Raleway', serif;
     font-size: 12px;
     line-height: 1.6em;
     letter-spacing: 0.4px;
}
 .text-light {
     color: #fff;
}
/* Links */
 a {
     transition: color .2s ease-in-out;
}
 a:hover, a:focus {
     text-decoration: none;
}
 *:focus {
     outline-style: none;
     box-shadow: none;
     border-color: transparent;
}
 .custom-link {
     font-family: 'Raleway', sans-serif;
     margin-top: 20px;
     background: #f3f3f3;
     display: inline-block;
     padding: 0px 10px;
     font-weight: 700;
     font-size: 20px;
}
/*=========================== Preloader ============================ */
 #preloader {
     background:#fff;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     z-index: 99999;
}
 .spinner {
     text-align: center;
     position: absolute;
     top: 50%;
     left: 46%;
     animation: rotate 2.0s infinite linear;
}
 .spinner > div {
     width: 18px;
     height: 18px;
     border-radius: 100%;
     display: inline-block;
     animation: bouncedelay 1.4s infinite ease-in-out;
    /* Prevent first frame from flickering when animation starts */
     animation-fill-mode: both;
}
 .spinner .bounce1 {
    animation-delay: -0.32s;
}
 .spinner .bounce2 {
    animation-delay: -0.16s;
}
 @keyframes bouncedelay {
     0%, 80%, 100% {
         transform: scale(0.0);
    }
     40% {
         transform: scale(1.0);
    }
}
/*=========================== Navigation ============================ */
 .navbar-default .navbar-nav>li>a {
     font-size: 15px;
     color: #fff;
     font-weight: 700;
     border-bottom: 1px solid transparent;
     display: inline-block;
     text-decoration: none;
     padding: 5px;
     letter-spacing: 0.5px;
     font-family: 'Raleway', serif;
}
 .navbar-default .navbar-nav>li>a::after {
     content: '';
     display: block;
     width: 0;
     margin-top: 5px;
     height: 1px;
     transition: width .8s;
}
 .navbar-default .navbar-nav>li>a:hover::after {
     width: 100%;
     transition: width .8s;
}
 .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
     background: transparent;
}
 .navbar-header {
     float: left;
     width: 100%;
}
 .navbar-default {
     background: transparent;
}
 .navbar-default .navbar-toggle {
     transition: all 0.3s ease-in-out;
}
 .navbar-default .navbar-toggle .icon-bar {
     background-color: #fff;
}
/* Dropdown */
 .dropdown-menu {
     text-align: center;
     width: 100%;
}
 .dropdown-menu>li>a {
     transition: all 0.8s;
     display: block;
     padding: 5px 20px;
     clear: both;
     font-weight: 700;
     color: #333;
     white-space: nowrap;
}
 .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
     color: #fff;
}
 .navbar-nav>li {
     float: none;
}
 .navbar-nav {
     float: left;
     margin: 20px 0px;
     width: 100%;
}
 .navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover{
     background:transparent!important;
     color:#fff;
}
 .nav .open>a, .nav .open>a:focus, .nav .open>a:hover{
    border:0px;
}
/* Brand */
 .brand {
     margin-bottom: 0px;
     text-align: center;
     max-width: 160px;
     margin: auto;
}
/*=========================== Slideshow ============================ */
 .slideshow {
     position: absolute;
     width: 100%;
     height: 100%;
     z-index: 0;
     overflow: hidden;
}
 .slideshow-image {
     position: absolute;
     width: 100%;
     height: 100%;
     background: no-repeat 50% 50%;
     background-size: cover;
     -webkit-animation-name: kenburns;
     animation-name: kenburns;
     -webkit-animation-timing-function: linear;
     animation-timing-function: linear;
     -webkit-animation-iteration-count: infinite;
     animation-iteration-count: infinite;
     -webkit-animation-duration: 26s;
     animation-duration: 26s;
     opacity: 1;
     -webkit-transform: scale(1.2);
     transform: scale(1.2);
}
/* Ken Burns animation */
 .slideshow-image:nth-child(1) {
     background-image: url('../img/slideshow/slide1.jpg');
     -webkit-animation-name: kenburns-1;
     animation-name: kenburns-1;
     z-index: 3;
}
 .slideshow-image:nth-child(2) {
     background-image: url('../img/slideshow/slide2.jpg');
     -webkit-animation-name: kenburns-2;
     animation-name: kenburns-2;
     z-index: 2;
}
 .slideshow-image:nth-child(3) {
     background-image: url('../img/slideshow/slide3.jpg');
     -webkit-animation-name: kenburns-3;
     animation-name: kenburns-3;
     z-index: 1;
}
 .slideshow-image:nth-child(4) {
     background-image: url('../img/slideshow/slide4.jpg');
     -webkit-animation-name: kenburns-4;
     animation-name: kenburns-4;
     z-index: 0;
}
 @-webkit-keyframes kenburns-1 {
     0% {
         opacity: 1;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
     1.5625% {
         opacity: 1;
    }
     23.4375% {
         opacity: 1;
    }
     26.5625% {
         opacity: 0;
         -webkit-transform: scale(1);
         transform: scale(1);
    }
     100% {
         opacity: 0;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
     98.4375% {
         opacity: 0;
         -webkit-transform: scale(1.21176);
         transform: scale(1.21176);
    }
     100% {
         opacity: 1;
    }
}
 @keyframes kenburns-1 {
     0% {
         opacity: 1;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
     1.5625% {
         opacity: 1;
    }
     23.4375% {
         opacity: 1;
    }
     26.5625% {
         opacity: 0;
         -webkit-transform: scale(1);
         transform: scale(1);
    }
     100% {
         opacity: 0;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
     98.4375% {
         opacity: 0;
         -webkit-transform: scale(1.21176);
         transform: scale(1.21176);
    }
     100% {
         opacity: 1;
    }
}
 @-webkit-keyframes kenburns-2 {
     23.4375% {
         opacity: 1;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
     26.5625% {
         opacity: 1;
    }
     48.4375% {
         opacity: 1;
    }
     51.5625% {
         opacity: 0;
         -webkit-transform: scale(1);
         transform: scale(1);
    }
     100% {
         opacity: 0;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
}
 @keyframes kenburns-2 {
     23.4375% {
         opacity: 1;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
     26.5625% {
         opacity: 1;
    }
     48.4375% {
         opacity: 1;
    }
     51.5625% {
         opacity: 0;
         -webkit-transform: scale(1);
         transform: scale(1);
    }
     100% {
         opacity: 0;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
}
 @-webkit-keyframes kenburns-3 {
     48.4375% {
         opacity: 1;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
     51.5625% {
         opacity: 1;
    }
     73.4375% {
         opacity: 1;
    }
     76.5625% {
         opacity: 0;
         -webkit-transform: scale(1);
         transform: scale(1);
    }
     100% {
         opacity: 0;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
}
 @keyframes kenburns-3 {
     48.4375% {
         opacity: 1;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
     51.5625% {
         opacity: 1;
    }
     73.4375% {
         opacity: 1;
    }
     76.5625% {
         opacity: 0;
         -webkit-transform: scale(1);
         transform: scale(1);
    }
     100% {
         opacity: 0;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
}
 @keyframes kenburns-4 {
     73.4375% {
         opacity: 1;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
     76.5625% {
         opacity: 1;
    }
     98.4375% {
         opacity: 1;
    }
     100% {
         opacity: 0;
         -webkit-transform: scale(1);
         transform: scale(1);
    }
}
 @-webkit-keyframes kenburns-4 {
     73.4375% {
         opacity: 1;
         -webkit-transform: scale(1.2);
         transform: scale(1.2);
    }
     76.5625% {
         opacity: 1;
    }
     98.4375% {
         opacity: 1;
    }
     100% {
         opacity: 0;
         -webkit-transform: scale(1);
         transform: scale(1);
    }
}
/*=========================== Structure ============================ */
/* Split the screen */
 .split {
     height: 100%;
     position: fixed;
     z-index: 1;
     top: 0;
}
 .col-md-10.split, .col-md-2.split {
     padding-right: 0px;
     padding-left: 0px;
}
/* Control the sidebar */
 #sidebar {
     padding: 20px;
     left: 0;
     padding: 20px;
     text-align: center;
}
/* Control the content side */
 #content {
     right: 0%;
}
 #content.pages{
     overflow-y:scroll;
     padding-bottom:50px;
}
/* Content box */
 .content-box {
     padding: 0px;
     width: 100%;
     background: rgba(255, 255, 255, 0.94);
     margin-top: 0px;
     margin-bottom: 0px;
     float:left;
     opacity: 0;
     -webkit-animation: slidein 1s ease 4s forwards;
     -moz-animation: slidein 1s ease 4s forwards;
     -o-animation: slidein 1s ease 4s forwards;
     animation: slidein 1s ease 4s forwards;
     animation-delay: 1s;
}
 .content-wrapper {
     overflow-y: scroll;
     height: 100%;
     width: 100%;
     position: absolute;
}
 .inside-wrapper {
     padding: 70px!important;
}
 .inside-wrapper.home {
     padding: 40px!important;
}
/* Content box animation */
 @keyframes slidein {
     0% {
         opacity: 0;
         transform: translatey(-15px);
    }
     100% {
         opacity: 1;
         transform: translateY(0);
    }
}
/* background images in each section */
 #services .content-wrapper {
     background-size: cover!important;
     /* background: url("../img/backgrounds/bg1.jpg"); */
}
 #aboutus .content-wrapper {
     background-size: cover!important;
     /* background: url("../img/backgrounds/bg2.jpg"); */
}
 #contact .content-wrapper {
     background-size: cover!important;
     /* background: url("../img/backgrounds/bg3.jpg"); */
}
 #gallery .content-wrapper {
     background-size: cover!important;
     /* background: url("../img/backgrounds/bg4.jpg"); */
}
 #prices .content-wrapper {
     background-size: cover!important;
     /* background: url("../img/backgrounds/bg5.jpg"); */
}
/*=========================== Services ============================ */
 .serviceBox {
     margin-bottom: 20px;
     text-align: center;
}
 .service-icon img {
     border: 5px solid;
     max-width: 80%;
     margin: 0 auto;
     transition: all 0.3s ease-in-out;
}
 .service-icon i {
     font-size: 90px;
     transition: all 0.3s ease-in-out;
}
 .serviceBox .service-content {
     margin-top: 30px;
}
 .service-hover-box img {
     transition: all 0.8s;
}
/*=========================== About us ============================ */
/* team */
 figure.team {
     background-color: #fff;
     padding: 30px;
     box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
 figure.team * {
     box-sizing: border-box;
     transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}
 figure.team figcaption {
     position: relative;
     margin-top: 20px;
}
 figure.team h4 {
     margin-bottom: 0px;
}
 figure.team h6 {
     margin-top: 0px;
}
 figure.team .icons {
     position: absolute;
     top: 25px;
     left: 25px;
}
 figure.team i {
     margin-right: 5px;
     display: inline-block;
     font-size: 28px;
     width: 40px;
     height: 40px;
     line-height: 40px;
     text-align: center;
     background: white;
     opacity: 0;
     transform: rotateX(90deg);
     box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
 figure.team a {
     opacity: 0.8;
}
 figure.team a:hover {
     opacity: 1;
}
 figure.team:hover img {
     opacity: 0.3;
     filter: grayscale(100%);
}
 figure.team:hover i {
     opacity: 1;
     transform: rotateX(0);
}
 figure.team:hover a:first-child i {
     transition-delay: 0.1s;
}
 figure.team:hover a:nth-child(2) i {
     transition-delay: 0.2s;
}
 figure.team:hover a:nth-child(3) i {
     transition-delay: 0.3s;
}
/* testimonials */
 .testimonial {
     margin: 0 15px;
}
 .testimonial .content:before {
     content: "\f10d";
     font-family: "Font Awesome 5 Free";
     font-weight: 900;
     display: inline-block;
     position: absolute;
     top: -30px;
     left: -10px;
     font-size: 36px;
}
 .testimonial .content {
     padding: 30px;
     border-radius: 6px;
     margin-bottom: 15px;
     position: relative;
}
 .testimonial .content:after {
     content: "";
     border-left: 10px solid transparent;
     border-right: 9px solid transparent;
     position: absolute;
     bottom: -8px;
     left: 16%;
}

.testimonial .content.without-before-after:before {
    content: "";
}

 .testimonial .description {
     margin-bottom: 10px;
     font-size:18px;
     font-weight: 400;
     line-height: 26px;
     font-family: 'Raleway', sans-serif;
}
 .testimonial-pic {
     float: left;
}
 .testimonial-pic>img {
     max-width: 120px;
     border-radius: 50%;
     border: 2px solid #e5e5e5;
     margin-left: 20px;
}
 .testimonial .testimonial-review {
     margin: 3px 0 0 15px;
     float: left;
}
 .testimonial .testimonial-title {
     margin-bottom: 0px;
}
 .testimonial .testimonial-review span {
     font-size: 22px;
}
/*=========================== Prices ============================ */
 #prices .nav-tabs{
     margin-bottom:30px;
}
 #prices .img-price {
     transition: all 0.35s ease-in-out;
     width: 120px;
     margin-bottom: 20px;
}
 #prices .nav-tabs li.active .img-price {
     border: 10px solid;
}
 #prices .nav-tabs li:hover .img-price {
     border: 5px solid;
}
 #prices .tab-pane {
     text-align: center;
}
 .prices-main {
     margin-top: 30px;
}
/* Menu styling */
 .menu-body {
     margin: 0 auto;
     display: block;
}
 .menu-item {
     margin: 45px 0px;
     font-size: 20px;
}
/* menu Name */
 .menu-item-name {
     text-align: left;
     font-weight: 700;
     font-size: 18px;
     border-bottom: 1px dotted rgb(213, 213, 213);
}
/* menu Description */
 .menu-item-description {
     font-size: .8em;
     line-height: 1.5em;
     margin-top: 10px;
     text-align: left;
}
/* menu Price */
 .menu-item-price {
     float: right;
     color: #333;
     margin-top: -32px;
     font-size: 22px;
}

.menu-item-price .font-size-small {
    font-size: 16px;
}
/* =========================== Gallery ============================ */
/* Isotope */
 .isotope-item {
     z-index: 2 
}
 .isotope-hidden.isotope-item {
     z-index: 1 
}
 .isotope, .isotope .isotope-item {
     transition-duration: 0.8s;
}
 .isotope-item {
     padding: 2px;
     margin-right: -1px;
     backface-visibility: hidden;
}
 .isotope {
     transition-property: height, width;
}
 .isotope .isotope-item {
     transition-property: transform, opacity;
}
/* Gallery thumbnail*/
 .gallery-thumb {
     display: block;
     position: relative;
     overflow: hidden;
}
 .gallery-thumb img {
     transition: all 1s ease-out;
}
 .gallery-thumb:hover img {
     transform: scale(1.2) rotate(-5deg);
}
 .scrollimation .gallery-thumb {
     transform: translateY(100px);
     opacity: 0;
     transition: opacity .4s ease-out, transform .4s ease-out;
}
 .touch .scrollimation .gallery-thumb, .scrollimation .gallery-thumb.in {
     transform: translateY(0px);
     opacity: 1;
}
/* thumbnail Overlay */
 .gallery-thumb .overlay-mask {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     opacity: 0;
     z-index: 1;
     transition: opacity .3s ease-out;
     background: rgba(0, 0, 0, 0.53);
}
 .gallery-thumb:hover .overlay-mask {
     opacity: 1;
}
/*=========================== Contact ============================ */
 .contact-icon {
     text-align: center;
     padding: 30px;
     min-height: 210px;
     border: 2px solid;
}
 .contact-icon-info {
     margin-top: 30px;
}
 .contact-icon-info p {
     font-weight: 700;
}
 .contact-icon .top-icon {
     transition: all .3s ease-out;
     font-size: 30px;
     top: -30px;
     position: absolute;
     right: 0;
     left: 0;
     width: 100px;
     margin: auto;
     border-radius: 50%;
     width: 60px;
     height: 60px;
     line-height: 60px;
     color: #fff;
}
 .contact-icon:hover .top-icon {
     top: -40px;
}
 .margin-icon {
     margin-right: 10px;
}
/* Map */
 #map-canvas {
     min-width: 100%;
     height: 300px;
}
/*=========================== Blog pages ============================ */
 .sidebar-header{
    text-align:center;
     margin-bottom:40px;
}
 .blog-head{
     background-image: url("../img/slideshow/slide1.jpg");
     padding:40px;
     box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.8);
     margin-bottom:50px;
}
 #blog-container {
    padding: 0px 40px;
}
/* date & category badge */
 .date-category {
     position: absolute;
     z-index: 10;
     color: #fff;
     min-width: 130px;
     padding: 20px;
     left: 50px;
     margin-top:30px;
}
 .date-category p {
     color: #fff;
     font-family: Raleway;
     font-size: 20px;
     line-height: 24px;
     margin:10px 0px 0px 0px;
}
/* blog-popular widget*/
 .blog-popular img{
    padding:10px;
}
 .blog-popular .col-xs-7{
    padding-left:0px;
}
 .blog-popular h6{
    margin-bottom:5px;
     line-height: 1.2em;
     font-size: 1.2em;
}
 .blog-popular p{
    font-size:12px;
}
 .blog-popular a:hover img{
    opacity:0.8;
}
/* Post info */
 .post-info p {
     margin-right: 15px;
     display: inline-block;
     font-weight: 900;
     font-size: 11px;
     text-transform: uppercase;
     letter-spacing: 0.5px;
}
 .post-info p + p {
    line-height: 0px !important;
}
 .post-info i {
    margin-right: 5px;
}
/* blog post */
 .blog-post{
     border-bottom: 1px solid #f5f5f5;
     margin-bottom:30px;
     padding-bottom:50px;
}
 .blog-post:last-child{
    border-bottom: 0px;
}
 .blog-post h3 {
     margin-top: 20px;
     transition: all 0.2s ease-in-out;
}
 .blog-post i {
    margin-right : 5px;
}
 .single-page .blog-post h3{
    margin-top:0px;
}
 .post-main img{
    margin-bottom:20px;
}
/* Blog tags */
 .blog-tags a {
     border-radius: 0px;
     color: #fff;
     display: inline-block;
     height: 26px;
     line-height: 26px;
     padding: 0 20px 0 23px;
     position: relative;
     margin: 0 10px 10px 0;
     text-decoration: none;
     transition: all 0.2s ease-in-out;
}
 .blog-tags a::before {
     background: #fff;
     border-radius: 5px;
     box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
     content: '';
     height: 6px;
     left: 10px;
     position: absolute;
     width: 6px;
     top: 10px;
}
 .post-info .blog-tags {
    margin-top:20px;
}
/* Comments */
 .comment-form.media{
    margin-top:50px;
}
 .comments-block{
     margin-top:50px;
}
 .comment.media-body {
    margin-right: 10px;
}
 .comments-block .btn{
    padding: 10px;
}
 .comments-block .media-object{
     max-width:120px;
     float: left;
     margin-right:20px;
}
 .comment {
     margin: 40px auto 20px;
     padding: 30px;
     position: relative;
}
 .nested {
    display: inline-block;
}
/* blog-single */
 .blog-single{
    margin-bottom:40px;
    border-bottom: 1px dotted #cecece;
    padding-bottom: 40px;
}
 .img-blog img {
     -webkit-transition: .7s ease-in-out;
     transition: .7s ease-in-out;
}
 .img-blog img:hover{
    -webkit-filter: grayscale(100%);
     filter: grayscale(100%);
}
/*=========================== Miscellaneous ============================ */
/* light text */
 .text-light p, .text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6 {
     color: #fff!important;
}
/* progress bar */
 .progress {
     margin: 5px;
}
/* Nav-tabs */
 .nav-tabs {
     text-align: center;
     border-bottom: 0;
     margin-bottom: 60px;
}
 .nav.nav-tabs li {
     margin: 0px 10px;
     position: relative;
     float: none;
     display: inline-block;
}
 .nav.nav-tabs li.active:before {
     content: "";
     position: absolute;
     bottom: -28px;
     left: 40%;
     border: 14px solid transparent;
}
 .nav.nav-tabs li.active:after {
     content: "";
     position: absolute;
     bottom: -20px;
     left: 53px;
     border: 10px solid transparent;
}
 .nav.nav-tabs li a {
     font-family: 'Raleway', sans-serif;
     border-bottom: 1px solid;
     font-weight: 700;
     padding: 13px 35px;
     font-size: 20px;
     background: transparent;
     border-radius: 0;
     border-top: 0;
     border-left: 0;
     border-right: 0;
}
 .nav.nav-tabs>li:focus>a, .nav.nav-tabs>li.active>a, .nav.nav-tabs>li.active>a:hover, .nav.nav-tabs>li.active>a:focus {
     border-bottom: 2px solid;
     border-left: 0;
     border-right: 0;
     border-top: 0;
     background: transparent;
}
/* Pager */
 .pager li>a, .pager li>span {
     display: inline-block;
     padding: 10px 24px;
     font-size: 18px;
     color: #fff;
     font-family: 'Open Sans', sans-serif;
     border-radius: 0px;
     transition: all .2s ease-in-out;
}
 .pager li>a:hover {
     color: #fff;
}
/* buttons */
 a.btn:hover, a.btn-primary:hover {
     color: #FFF;
}
 a.btn:focus, a.btn-primary:focus {
     color: #FFF;
}
 .btn, .btn-primary {
     font-size: 16px;
     letter-spacing: 0.3px;
     text-transform: uppercase;
     display: inline-block;
     text-align: center;
     border: 0;
     position: relative;
     overflow: hidden;
     font-weight:700;
     color:#fff!important;
     margin-top: 20px;
     text-decoration: none;
     -webkit-transition: 0.5s all ease;
     transition: 0.5s ease all;
     padding: 10px 40px;
     border-radius: 0px;
}
 .btn+.btn {
     margin-left: 10px;
}
 .btn-primary, .btn-secondary {
     transition: background .2s ease-in-out;
}
 .btn-lg {
     padding: 1.5rem 2.4rem!important;
     font-size: 1.25rem!important;
     line-height: 1.5;
}
 .btn-sm {
     padding: 0.6rem 0.6rem!important;
     font-size: 0.9rem!important;
}
 .btn-light {
     color: #212529!important;
}
 .btn-group-vertical>.btn, .btn-group>.btn {
     margin: 0px;
}
 .btn-primary:hover, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active {
     outline: none;
     box-shadow: none!important;
     background-color: transparent;
}
 button:focus {
     outline: 0px;
}
 .navbar-default, .navbar-default .navbar-collapse, .navbar-default .navbar-form {
     border: 0;
}
/* social media */
 .social-media i {
     font-size: 20px;
     padding: 10px;
     margin: 0px;
}
 .social-media i:hover {
     color: #cecece;
     transition: all .5s ease-in-out;
}
 .navbar-info {
     text-align: center;
     color: #fff;
}
/* forms */
 .form-control {
     border: 0;
     background: transparent;
     border-bottom: 2px solid #6a2c3b;
     transition: all .15s ease-in-out;
     border-radius: 0px;
     box-shadow: none;
}
 .form-control input {
     height: 50px;
}
 .form-control:focus {
     outline: none;
     box-shadow: none;
}
 .input-group-btn .btn {
     margin-top: 0px;
     margin-left: 0px;
     border-radius: 0px;
}
 .input-group .form-control:first-child{
    height: 42px;
}
 label {
     margin-top: 20px;
    font-size: 16px;
}
/* Blockquote */
 blockquote {
     padding: 20px;
     font-weight: 700;
     font-family: 'Raleway', sans-serif;
}
 blockquote footer {
     padding: 0px;
     background: transparent!important;
     color: inherit;
}
 blockquote p {
     font-weight: 700;
     font-size: 18px;
}
 cite:before {
     content: '― ';
}
 cite {
     font-style: normal;
}
 blockquote:before {
     margin-right: 20px;
     content: "\f10d";
     font-weight: 900;
     font-style: normal;
     font-family: "Font Awesome 5 Free";
     font-size: 2em;
     opacity: 0.7;
}
/* margins */
 .margin1 {
     margin-top: 50px;
}
 .margin-i {
     margin-right: 10px;
}
/* fade effect */
 .fade {
     transition: opacity 1.15s linear;
}
/* Nav-pills */
 .nav-pills>li>a {
     transition: all .2s ease-in-out;
     border-radius: 0px;
     font-size: 16px;
     margin: 5px;
     text-transform: uppercase;
     padding: 10px 25px;
     font-weight: 700;
     letter-spacing: .15em;
}
 .nav-pills>li.active>a, .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus, .nav-pills>li>a:hover, .nav-pills>li>a, .nav-pills .nav>li>a:hover {
     color: #fff;
}
 .nav-tabs.centered>li, .nav-pills.centered>li {
     float: none;
     display: inline-block;
     zoom: 1;
}
 .nav-tabs.centered, .nav-pills.centered {
     text-align: center;
}
/* light background */
 .bg-light {
     background: #f5f5f5!important;
}
/* section-heading */
 .section-heading {
     text-align: center;
     margin-bottom: 70px;
}
 .section-heading :after {
     content: ' ';
     display: block;
     border: 1px solid;
     border-radius: 4px;
     -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
     box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
     -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
     width: 80px;
     margin: 0 auto;
     margin-top: 20px;
}
/*Paragraph image */
 .img-paragraph {
     padding: 20px;
}
/* custom ul */
 ul.custom {
     padding-left: 0px;
     list-style: none;
}
 ul.custom li {
     padding: 5px 0px;
     font-size: 16px;
     line-height: 26px;
}
 ul.custom li:before {
     font-family: "Font Awesome 5 Free";
     content: "\f35a";
     padding-right: 7px;
}
/* Owl-carousel */
 .owl-prev, .owl-next {
     position: absolute;
     top: 50%;
     border-radius: 50%;
     text-align: center;
}
 .owl-nav {
     margin: 0 auto;
     top: -20px;
     left: 50%;
}
 .owl-prev {
     left: -15px;
}
 .owl-next {
     right: -15px;
}
 .owl-prev i, .owl-next i {
     transition: all .3s ease-in-out;
     border-radius: 50%;
     font-size: 20px;
     height: 30px;
     color: #fff;
     width: 30px;
     line-height: 30px;
}
 .owl-carousel.owl-loaded {
     display: block;
     clear: both;
    /* Important fix */
}
 .owl-dots {
     text-align: center;
     margin-top: 10px;
}
 .owl-theme .owl-dots .owl-dot {
     display: inline-block;
}
 .owl-theme .owl-dots .owl-dot span {
     border: 2px solid #cecece;
     position: relative;
     display: inline-block;
     vertical-align: top;
     width: 1rem;
     height: 1rem;
     margin: 0 0.5rem;
     border-radius: 50%;
     cursor: pointer;
     color: #333;
}
 .owl-theme .owl-dots .owl-dot span:before {
     content: "";
     position: absolute;
     left: 50%;
     top: 50%;
     width: 1.2rem;
     height: 1.2rem;
     border-radius: 50%;
     transition: transform 0.3s;
     transform: translate(-50%, -50%) scale(0);
}
 .owl-theme .owl-dots .owl-dot span:hover:before {
     transform: translate(-50%, -50%) scale(1);
}
 .owl-theme .owl-dots .owl-dot.active span:before {
     transform: translate(-50%, -50%) scale(1);
}
 .owl-carousel .owl-stage {
     margin-top: 20px;
}
/* owl carousel with navigation on top */
 .top-centered-nav .owl-nav {
     position: absolute;
}
 .top-centered-nav .owl-prev, .top-centered-nav .owl-next {
     top: 0%;
}
 .top-centered-nav .owl-prev {
     left: -40px;
}
 .top-centered-nav .owl-next {
     right: -40px;
}
/* fade-effect owl carousel */
 .fade-effect.owl-carousel .owl-item {
     perspective: 1000px;
     opacity: 0.7;
     transform: scale(0.8);
     transition: all 1s ease-in-out;
}
 .fade-effect.owl-carousel .owl-item.active {
     opacity: 1;
     transform: scale(1);
}
/* labels */
 .label {
     font-size: 12px;
     padding: 10px;
}
/* Page margin*/
 .page {
     padding: 160px;
}
/* alerts */
 .alert {
     padding: 30px;
     border: 0px;
}
.alert h4{
	margin-top:-7px;
	margin-bottom: 10px;
}
 .alert-info:before {
     content: "\f128";
     z-index: 0;
     opacity: 0.8;
     font-size: 40px;
     font-family: "flaticon";
     z-index: 0;
     left: -10px;
     position: absolute;
     top: -20px;
}
 .alert-info:after {
     content: "\f128";
     z-index: 0;
     transform: rotate(-180deg);
     font-size: 40px;
     font-family: "flaticon";
     z-index: 0;
     right: -10px;
     position: absolute;
     bottom: -20px;
}
/* elements page */
 #elements .row {
     margin-right: 0px;
     margin-left: 0px;
}
 #elements .page-header{
    margin-top:80px;
}
 #elements h5.sub-header {
     padding: 10px;
     text-align: center;
     border-radius: 0px;
}
 #elements h3.sub-header {
     padding: 10px;
     margin-bottom: 30px;
     text-align: center;
     color: #fff;
     background: #cecece;
}
 #elements .bg-colorprev .col-md-3 {
     padding: 20px;
}
 .glyph {
     display: inline-block;
     text-align: center;
     vertical-align: top;
     padding: 20px;
     width: 24%;
}
 .glyph-icon {
     padding: 10px;
     display: block;
     margin-top: 20px;
     font-size: 52px;
     color: #2F2F2F;
     margin-left: 0;
}
 .page-header {
     border-bottom: 2px solid #eee !important;
     margin-bottom: 50px;
}
 .page-header h2 {
     display: inline-table;
}
/* Img Styles */
 .img-opacity:hover {
     opacity: 0.7;
}
 .img-rounded {
     border-radius: 40px;
}
/* Image Zoom Hover effect */
 .img-effect {
     overflow: hidden;
}
 .img-effect img {
     transition: opacity 0.7s, transform 2.5s;
}
 .img-effect:hover img {
     transform: scale3d(1.2, 1.2, 1);
}
/* Image opacity hover */
 .img-hover img {
     transition: opacity 0.2s;
     opacity: 1;
}
 .img-hover:hover img {
     opacity: 0.8;
}
/* Image border */
 .img-border {
     border: 4px double 
}
/* well */
 .well{
     background:#f5f5f2;
     padding:20px;
     box-shadow:none;
     border:0px;
}
 .well ul.custom{
    padding-left:40px;
}
/* pills /badges */
 .badge-pill {
     padding: 10px;
     margin: 3px;
}
 .badge-default {
     color: #fff;
}
/* pagination */
 .pagination>li>a:hover{
    color:#fff;
}
 .pagination>li>a, .pagination>li>span,.pagination>li>a:focus{
     transition: all 0.3s ease-in-out;
     background:#f5f5f2;
     border:0px;
     color:#808080;
    font-weight:700;
}
/* breadcrumbs */
 .breadcrumb {
     color: #fff;
     font-family: 'Lato', sans-serif;
     font-weight: 700;
     background: transparent;
     padding: 20px;
     text-transform: uppercase;
     font-size: 13px;
     display: inline-flex;
}
/* list group */
 .list-group-item {
     border-radius: 0px;
     font-family: 'Raleway', sans-serif;
     padding:10px;
     background: #f5f5f2;
}
 .a.list-group-item:focus, a.list-group-item:hover, button.list-group-item:focus, button.list-group-item:hover{
    color:#fff;
}
 .list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover{
    border-color:#ddd;
}
/* panels */
 .panel-heading a.collapsed{
    color:#000;
}
/*=========================== Media queries ============================ */
/* Small devices (landscape phones, 544px) */
 @media (max-width: 544px) {
     .blog-head {
         margin-bottom: 0px;
    }
     .blog-single {
         margin-bottom: 0px;
    }
     .spinner {
         left: 40%;
    }
     .btn, .btn-primary{
        padding:10px;
    }
     #elements .glyph {
         width: 100%;
    }
     .date-category i {
        display:none;
    }
     .date-category {
         position: relative;
         color: #fff;
         min-width: 0px;
         padding: 0px;
         left: 0px;
    }
     .breadcrumb {
        font-size: 11px;
         display:block;
    }
     #blog-container {
        padding: 20px;
    }
     .comment {
         padding: 0px;
    }
     .comments-block .media-object {
         max-width: 100%;
         float: none;
         margin-right: auto;
         margin-left: auto;
         margin-bottom: 20px;
    }
     .btn+.btn {
         margin-left: 0px;
    }
     .navbar-collapse.show, .navbar-collapse {
         max-height: 300px !important;
    }
     .input-group .form-control, .input-group-btn {
         display: inline-table;
         width: 100%;
         margin-top: 10px;
    }
     .testimonial-pic, .testimonial .testimonial-review {
         float: none;
         text-align: center;
    }
     .testimonial-pic>img, .testimonial .testimonial-review {
         margin: 0 auto;
    }
     .btn-lg {
         padding: 20px!important;
    }
     .back-to-top {
         right: 12px;
    }
     .inside-wrapper {
         padding: 20px 20px!important;
    }
}
/* Medium devices (tablets, 768px) The navbar toggle appears at this breakpoint */
 @media (max-width: 768px) {
    /* section */
     section {
         padding-top: 40px;
         padding-bottom: 40px;
    }
     .section-heading {
         margin-bottom: 40px;
    }
     .section-heading:before {
         font-size: 40px;
    }
    /* owl carousel navigation on top */
     .owl-nav {
         margin: 0 auto;
         top: 0px;
         left: 50%;
         position: absolute;
    }
     .owl-prev, .owl-next {
         top: 0%;
    }
     .owl-prev {
         left: -40px;
    }
     .owl-next {
         right: -40px;
    }
     .owl-carousel .owl-stage {
         margin-top: 50px;
    }
    /* typography */
     h1 {
         font-size: 2.4em;
    }
     h2 {
         font-size: 2.0em;
    }
     h3 {
         font-size: 1.7em;
    }
     h4 {
         font-size: 1.5em;
    }
     .h7 {
         font-size: 16px;
         line-height: 27px;
    }
     .header-p {
         font-size: 18px;
         line-height: 23px;
         margin-bottom: 10px;
    }
     .header-text h1 {
         margin-bottom: 20px;
         font-size: 5em;
    }
    /* others */
     footer {
         padding: 30px;
    }
     .contact-icon {
         margin-top: 50px;
    }
}
/* Medium- Large devices (desktops, 992px) */
 @media (max-width: 992px) {
     .contact-icon {
         margin-top: 50px;
    }
     .content-wrapper{
         margin-bottom: 50px;
    }
    /* slider */
     .header-text:before {
         font-size: 50px!important;
         margin-top: 30px;
    }
    /*top bar */
     .top-bar .contact-details li {
         font-size: 13px;
    }
     .top-bar .contact-details li {
         margin: 0px!important;
    }
     .top-bar .contact-details i {
         margin-right: 8px;
         margin-left: 8px;
    }
     .social-list, .contact-details {
         float: none!important;
         text-align: center;
    }
     .navbar-collapse.show {
         max-height: 400px;
    }
     .navbar-collapse {
         max-height: 400px;
    }
     .navbar-collapse::-webkit-scrollbar {
         width: 10px;
    }
     .navbar-collapse::-webkit-scrollbar-track {
         background-color: lightgray;
    }
     .navbar-collapse::-webkit-scrollbar-thumb {
         background-color: darkgray;
    }
     .navbar-collapse.show {
         overflow-y: scroll!important;
         overflow-x: hidden!important;
    }
}
/* Large devices (large desktops, min 1200px ) */
 @media (min-width: 1200px) {
     .affix-sidebar {
         width: 100%;
         position: absolute;
         left: 50%;
         top: 50%;
         transform: translate(-50%, -50%);
    }
	#home .content-wrapper{
		display: flex;
		justify-content: center;
		align-items: center;
   }
    /* content box home*/
     .content-box.home {
         padding: 10px!important;
         width: 60%;
    }
     .content-box.home h1{
         margin-top: 0px;
    }    
     .inside-wrapper.container{
        width:1000px;
    }
    /* navigation */
     .navbar-nav .nav-link {
         height: 60px;
    }
     .dropdown-menu {
         margin-top: 30px;
    }
}
/* Large devices (large desktops, max 1200px) */
 @media (max-width: 1199px) {
     .slideshow {
         min-height: 100%;
    }
     .content-box.home {
         padding: 0px 20px 30px 20px!important;
         width: 90%;
         left: 5%;
         margin-top: 0px!important;
         top: 30%;
         position: absolute;
    }
     .navbar {
         margin-bottom: 0px;
    }
     #services .content-wrapper, #aboutus .content-wrapper, #contact .content-wrapper, #gallery .content-wrapper, #prices .content-wrapper {
         background-size: 100% auto !important;
         background-repeat: no-repeat;
    }
     .content-box {
         background: #fff;
         width: 100%;
         margin-bottom: 150px;
    }
     .brand {
         max-width: 120px;
         float: left;
         margin-top: 10px;
    }
     .content-wrapper {
         top: 89px;
         min-height:100%;
    }
     .content-box.home {
         margin-bottom: 150px;
    }
    /* navigation */
     .dropdown-menu:before {
         display: none;
    }
     .nav-item {
         margin-top: 20px;
    }
     .dropdown-item, .navbar-nav li {
         text-align: center;
    }
     .navbar-collapse.show {
         display: block;
         padding-bottom: 40px;
    }
     #sidebar.split {
         height: auto;
         z-index: 99999;
    }
     #sidebar {
         padding: 0px;
         overflow: hidden;
    }
    /* navbar collapse */
     .navbar-custom li:after {
         display: none;
    }
     .navbar-header {
         float: none;
         margin-top: -2px;
         height: 91px;
    }
     .navbar-toggle {
         display: block;
         margin-top: 30px;
    }
     .navbar-collapse.collapse {
         display: none!important;
    }
     .navbar-collapse {
         max-height: 400px !important;
    }
     .navbar-collapse.collapse.in {
         display: block!important;
         padding: 20px 10px;
         max-height: 400px;
         overflow-y: scroll!important;
         overflow-x: hidden!important;
    }
     .navbar-collapse::-webkit-scrollbar {
         width: 20px;
    }
     .navbar-collapse::-webkit-scrollbar-track {
         background-color: lightgray;
    }
     .navbar-collapse::-webkit-scrollbar-thumb {
         background-color: darkgray;
    }
     .navbar-nav {
         margin: 0;
         float: none !important;
    }
     .navbar-right {
         line-height: 0px;
    }
     .navbar-nav li {
         float: none;
    }
     .navbar-nav li>.dropdown-menu {
         border: 0;
         float: none;
         width: auto;
         position: static;
         box-shadow: none;
         background: inherit;
    }
    /* dropdown on click */
     .navbar-default .navbar-nav .open .dropdown-menu>li>a {
         color: #fff;
    }
     .dropdown-menu {
         display: none !important;
    }
     .open .dropdown-menu {
         display: block !important;
    }
     #sidebar, #content {
         width: 100%;
    }
     #content.pages{
        padding-bottom:100px;
         padding-top: 89px;
    }
}
 
/* Large devices ( min 1500px) */
 @media (min-width: 1500px) {
 
 /* content box home*/
     .content-box.home {
         width: 50%!important;
    }
	
 }
